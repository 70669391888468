import React from 'react';
import About from './about/About';
import Product from './product/Product';
import Contact from './contact/Contact';
import './Home.css'
const Home = (props) => {
  return (
    <div className="main">
        <About></About>
        <Product></Product>
        <Contact></Contact>
    </div>
  );
}

export default Home;