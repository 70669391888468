import React from 'react';
import './About.css';
import { Container, Row, Col } from 'reactstrap';

const About = (props) => {

  return (
    <div className="about c2" id="about">
        <div>
          <Row>
          <Col xs="12" sm="12" md="4" lg="4" className="img-col">
            <img src="assets/me.jpg" class="img-me" alt="Foto Christian Wenger"></img>
          </Col>
          <Col xs="12" sm="12" md="8" lg="8">
          <div className="text-box">
          <h3>Leistungspaket für Sportvereine </h3>        <h4>Woher ich komme</h4>
Ich bin seit einigen Jahren begeisterter Organisator vieler Anlässe im Regionalsport. Mein Name ist Christian Wenger, ich bin anfangs 30. Seit vielen Jahren arbeite ich für den FC Staad und andere Vereine in verschiedenen Positionen. Ich kenne mich sehr gut aus mit Organisation von Anlässen. Mein zweites Standbein sind Websites für Vereine und die Betreuung der Social-Media-Aktivitäten.
<h4>Meine Aufgabe</h4>
Perfekte Organisation von Anlässen, Kommunikation nach aussen, Vereinszugehörigkeit stärken.

<h4>Mein Ziel</h4>
Anlässe, die perfekt funktionieren. Durch seriöse Planung und Kommunikation auf allen Kanälen. Damit den Ruf des Vereins fördern. Schlussendlich: Neue Mitglieder gewinnen, Vereinsfunktionäre entlasten
</div></Col>
          </Row>
        </div>
    </div>
  );
}

export default About;