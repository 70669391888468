import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Footer.css';
const Footer = (props) => {

  const [showImpressum, setImpressum] = useState(false);
  const [showPrivacy, setPrivacy] = useState(false);

  const toggleImpressum= () => setImpressum(!showImpressum);
  const togglePrivacy= () => setPrivacy(!showPrivacy);
    return (
      <footer className="footer c1">
          <div><span onClick={togglePrivacy}>Datenschutz</span> | <span onClick={toggleImpressum}>Impressum</span></div>
          <Modal isOpen={showImpressum} toggle={toggleImpressum}>
            <ModalHeader toggle={toggleImpressum}>Impressum</ModalHeader>
            <ModalBody>
              Verantwortlich für den Inhalt:<br />
              Christian Wenger<br />
              <br />
              Verantwortlich für die technische Umsetzung:<br />
              Fabian Schaper<br />
              <a href="https://schaper.li">schaper.li</a><br />
            </ModalBody>
          </Modal>
          <Modal isOpen={showPrivacy} toggle={togglePrivacy}>
            <ModalHeader toggle={togglePrivacy}>Datenschutzerklärung</ModalHeader>
            <ModalBody>
              Verantwortlich im Sinne der Datenschutzerklärung insbensondere der DSGVO:<br />
              Christian Wenger<br />
              Fuhraweg 9<br />
              9491 Ruggell<br />
              <br />
              <b>Welche Daten werden gesammelt?</b><br />
              Es werden keine Personenbezogenen Daten gesammelt.<br />
            </ModalBody>
          </Modal>
      </footer>
    );
  }
  
  export default Footer;