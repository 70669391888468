import React from 'react';
import './Product.css';
import { Container, Row, Col } from 'reactstrap';

const Product = (props) => {

  return (
    <div className="about c1" id="about">
        <div className="div about-div">
          <Row>
          <Col xs="12" sm="12" md="0" lg="0" className="img-col mobile">
            <img src="assets/product.jpg" alt="Fussballcamp" className="img-football"></img>
          </Col>
          <Col xs="12" sm="12" md="7" lg="6">
          <div className="product-box">
          <h4>Meine Leistungen</h4>
          <Row>
            <Col xs="12" md="6">
            <b>A)	Organisation Nachwuchscamps</b>
              <ul>
                <li>Für aktuellen Nachwuchs (Ferienbeschäftigung)</li>
                <li>Rekrutierung neuer Nachwuchs</li>
              </ul>
              <b>B)	Organisation Turniere</b>
              <ul>
                <li>Ausschreibung</li>
                <li>Spielpläne</li>
                <li>Helfereinsätze planen</li>
              </ul>
              <b>C)	Organisation Vereinsanlässe</b>
              <ul>
                <li>Vereinsanlass</li>
                <li>Vereinsausflug</li>
                <li>Saisonabschluss</li>
              </ul>
            </Col>
            <Col xs="12" md="6">
            <b>D)	Organisation Saison-Opening</b>
          <ul>
            <li>Teamfotos</li>
            <li>Portraitfotos</li>
            <li>Internes Turnier</li>
            <li>Verpflegung während Anlass</li>
          </ul>
          <b>E)	Betreuung IT / Social-Media</b>
          <ul>
            <li>Homepage</li>
            <li>Facebook</li>
            <li>Instagram</li>
          </ul>
            </Col>
          </Row>        
          
          


</div></Col>
          <Col xs="0" sm="0" md="5"  lg="6" className="img-col desktop">
            <img src="assets/product.jpg" alt="Fussballcamp" className="img-football"></img>
          </Col>
          </Row>
        </div>
    </div>
  );
}

export default Product;