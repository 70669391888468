import React, { useState } from 'react';
import './Contact.css';
import { Row, Col, Form, Input, FormGroup, Label, Button } from 'reactstrap';

const Contact = (props) => {
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();    
    console.log(e.target.name.value);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: e.target.name.value, email: e.target.email.value, content: e.target.content.value })
  };
  fetch('http://wenger-fuer-alle-faelle.ch:8083/json', requestOptions)
      .then(response => {
        setMessage('E-Mail wurde verschickt!');
        e.target.reset();
      })
  }
  return (
    <div className="c2 contact" id="contact">
<div>
          <Row>
          <Col xs="12" sm="12" md="4" lg="4" className="img-col">
            <img src="assets/contact.jpg" class="img-me" alt="Foto Christian Wenger"></img>
          </Col>
          <Col xs="12" sm="12" md="8" lg="8">
            <Form className="contact-form" onSubmit={handleSubmit} >
            <h3>Kontaktformular</h3>
            <FormGroup>
              <Label for="exampleEmail">Name</Label>
              <Input type="text" name="name" id="name" placeholder="Name" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input type="email" name="email" id="email" placeholder="E-Mail Adresse" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Nachricht</Label>
              <Input type="textarea" name="text" name="content" id="content" />
            </FormGroup>
            <br />
            <Button type="submit">Absenden</Button>
            <p>{ message }</p>
            </Form>
          </Col>
          </Row>
        </div>
    </div>
  );
}

export default Contact;